<template>
	<AppLayout v-if="authStore.isAuthenticated">
		<template #primary>
			<div class="bg-white min-h-full px-4 py-16 sm:px-6 sm:py-24 md:grid md:place-items-center lg:px-8">
				<div class="max-w-max mx-auto">
					<main class="sm:flex">
						<p class="text-4xl font-extrabold text-primary-600 sm:text-5xl">404</p>
						<div class="sm:ml-6">
							<div class="sm:border-l sm:border-gray-200 sm:pl-6">
								<h1 class="text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">Page not found</h1>
							</div>
							<div class="mt-10 flex space-x-3 sm:border-l sm:border-transparent sm:pl-6">
								<a href="#" class="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-black"> Contact support </a>
								<a href="/" class="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-primary-600 hover:bg-primary-700"> Go home </a>
							</div>
						</div>
					</main>
				</div>
			</div>
		</template>
	</AppLayout>
	<div v-else class="bg-black min-h-full h-full flex flex-col lg:relative">
		<main
			class="min-h-full bg-cover bg-top sm:bg-top md:bg-center"
			style="background-image: url('https://images.unsplash.com/photo-1472457897821-70d3819a0e24?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=3050&q=80&exp=8&con=-15&sat=-75')"
			:style="{ 'background-position-x': baseStore.isMobile ? '-530px' : '' }"
		>
			<div class="mx-auto max-w-7xl px-4 text-center sm:px-6 lg:px-8 lg:py-48 flex flex-col flex-grow h-full justify-center -mt-10">
				<img alt="Workflow" class="h-48 w-auto mx-auto mb-16" src="/logo.svg" />
				<p class="font-bold text-primary text-6xl">404</p>
				<h1 class="mt-4 text-3xl sm:text-4xl font-extrabold sm:font-bold tracking-tight text-black sm:text-5xl">Uh oh! I think you’re lost.</h1>
				<p class="mt-2 sm:text-lg font-medium text-primary">These weren't the droids you were looking for!</p>
				<div class="mt-6">
					<a href="#" class="inline-flex items-center rounded-md border border-transparent bg-black text-white px-4 py-2 text-sm font-medium">Contact support</a>
					<a href="/" class="ml-6 inline-flex items-center rounded-md border border-transparent bg-primary text-white px-4 py-2 text-sm font-medium">Go back home</a>
				</div>
			</div>
		</main>
	</div>
</template>

<script setup lang="ts">
	import AppLayout from '@layouts/AppLayout.vue';
	import { useAuthStore } from '@modules/auth/store';
	import { useBaseStore } from '@store';

	const authStore = useAuthStore();
	const baseStore = useBaseStore();
</script>

<style scoped></style>
